
export function addWord_types()
{
    return [
    //lem
    {text:'',type:'E-lem',status_id:'',sub_id:'',comment:''},//Bokmal
    // {text:'',type:'E-div',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-lem',status_id:'',sub_id:'',comment:''},//Nynorsk
    {text:'',type:'B-lem',status_id:'',sub_id:'',comment:''},//English
    {text:'',type:'T-lem',status_id:'',sub_id:'',comment:''},	//Tamil
    {text:'',type:'Kku-lem',status_id:'',sub_id:'',comment:''},//Kurdish(Kurmanji)
    {text:'',type:'Kso-lem',status_id:'',sub_id:'',comment:''},//Kurdish(Sorani)
    {text:'',type:'Som-lem',status_id:'',sub_id:'',comment:''},//Somali
    {text:'',type:'Tir-lem',status_id:'',sub_id:'',comment:''},//Tigrinja
    {text:'',type:'Ara-lem',status_id:'',sub_id:'',comment:''},//Arabic
    {text:'',type:'Tur-lem',status_id:'',sub_id:'',comment:''},//Turkish
    {text:'',type:'Per-lem',status_id:'',sub_id:'',comment:''},//Persian
    {text:'',type:'Th-lem',status_id:'',sub_id:'',comment:''},//Thai
    {text:'',type:'Ur-lem',status_id:'',sub_id:'',comment:''},//Urdu
    {text:'',type:'Ru-lem',status_id:'',sub_id:'',comment:''},//Russian
    {text:'',type:'Pol-lem',status_id:'',sub_id:'',comment:''},//Polish
    {text:'',type:'Vie-lem',status_id:'',sub_id:'',comment:''},//Vietnamese
    {text:'',type:'Prs-lem',status_id:'',sub_id:'',comment:''},//Dari
    {text:'',type:'Tgl-lem',status_id:'',sub_id:'',comment:''},//Tagalog
    {text:'',type:'Bur-lem',status_id:'',sub_id:'',comment:''},//Burmese
    {text:'',type:'Lit-lem',status_id:'',sub_id:'',comment:''},//Lithuanian
    {text:'',type:'Ukr-lem',status_id:'',sub_id:'',comment:''},//Ukrainian
    //kat
    {text:'',type:'E-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'B-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'T-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kku-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kso-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Som-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tir-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ara-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tur-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Per-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Th-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ur-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ru-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Pol-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Vie-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Prs-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tgl-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Bur-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Lit-kat',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ukr-kat',status_id:'',sub_id:'',comment:''},
    //alt
    {text:'',type:'E-alt',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'B-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'T-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Kku-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Kso-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Som-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tir-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ara-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tur-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Per-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Th-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ur-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ru-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Pol-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Vie-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Prs-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tgl-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Bur-alt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Lit-alt',status_id:'',sub_id:'',comment:''},
    //def
    {text:'',type:'E-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'B-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'T-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kku-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kso-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Som-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tir-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ara-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tur-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Per-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Th-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ur-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ru-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Pol-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Vie-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Prs-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tgl-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Bur-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Lit-def',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ukr-def',status_id:'',sub_id:'',comment:''},
    //til
    {text:'',type:'E-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'B-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'T-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kku-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kso-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Som-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tir-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ara-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tur-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Per-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Th-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ur-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ru-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Pol-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Vie-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Prs-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tgl-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Bur-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Lit-til',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ukr-til',status_id:'',sub_id:'',comment:''},
    //kom
    {text:'',type:'E-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'B-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'T-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kku-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kso-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Som-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tir-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ara-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tur-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Per-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Th-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ur-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ru-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Pol-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Vie-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Prs-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tgl-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Bur-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Lit-kom',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ukr-kom',status_id:'',sub_id:'',comment:''},
    //mor
    {text:'',type:'E-mor',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'B-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'T-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Kku-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Kso-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Som-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tir-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ara-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tur-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Per-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Th-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ur-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ru-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Pol-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Vie-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Prs-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tgl-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Bur-mor',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Lit-mor',status_id:'',sub_id:'',comment:''},
    //utt
    {text:'',type:'E-utt',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'B-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'T-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Kku-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Kso-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Som-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tir-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ara-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tur-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Per-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Th-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ur-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Ru-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Pol-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Vie-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Prs-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Tgl-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Bur-utt',status_id:'',sub_id:'',comment:''},
    // {text:'',type:'Lit-utt',status_id:'',sub_id:'',comment:''},
    //grm
    {text:'',type:'E-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Nny-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'B-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'T-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kku-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Kso-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Som-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tir-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ara-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tur-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Per-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Th-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ur-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ru-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Pol-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Vie-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Prs-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Tgl-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Bur-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Lit-grm',status_id:'',sub_id:'',comment:''},
    {text:'',type:'Ukr-grm',status_id:'',sub_id:'',comment:''},
    //sms
    {text:'',type:'E-sms',status_id:'',sub_id:'',comment:'',index:0},
    // {text:'',type:'E-div',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Nny-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'B-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'T-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Kku-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Kso-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Som-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tir-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ara-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tur-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Per-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Th-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ur-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ru-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Pol-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Vie-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Prs-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tgl-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Bur-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Lit-sms',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ukr-sms',status_id:'',sub_id:'',comment:'',index:0},
    //idi
    {text:'',type:'E-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Nny-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'B-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'T-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Kku-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Kso-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Som-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tir-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ara-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tur-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Per-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Th-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ur-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ru-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Pol-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Vie-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Prs-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tgl-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Bur-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Lit-idi',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ukr-idi',status_id:'',sub_id:'',comment:'',index:0},
    //eks
    {text:'',type:'E-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Nny-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'B-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'T-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Kku-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Kso-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Som-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tir-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ara-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tur-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Per-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Th-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ur-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ru-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Pol-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Vie-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Prs-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Tgl-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Bur-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Lit-eks',status_id:'',sub_id:'',comment:'',index:0},
    {text:'',type:'Ukr-eks',status_id:'',sub_id:'',comment:'',index:0},
    //div
    
]
}
export function editWord_types()
{
    return [
    //lem
    {text:'',type:'E-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Bokmal
    {text:'',type:'N-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Nynorsk
    {text:'',type:'B-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//English
    {text:'',type:'T-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},	//Tamil
    {text:'',type:'Kku-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Kurdish(Kurmanji)
    {text:'',type:'Kso-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Kurdish(Sorani)
    {text:'',type:'Som-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Somali
    {text:'',type:'Tir-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Tigrinja
    {text:'',type:'Ara-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Arabic
    {text:'',type:'Tur-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Turkish
    {text:'',type:'Per-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Persian
    {text:'',type:'Th-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Thai
    {text:'',type:'Ur-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Urdu
    {text:'',type:'Ru-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Russian
    {text:'',type:'Pol-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Polish
    {text:'',type:'Vie-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Vietnamese
    {text:'',type:'Prs-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Dari
    {text:'',type:'Tgl-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Tagalog
    {text:'',type:'Bur-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Burmese
    {text:'',type:'Lit-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Lithuanian
    {text:'',type:'Ukr-lem',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},//Ukranian
    //kat
    {text:'',type:'E-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'N-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'B-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'T-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kku-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kso-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Som-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tir-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ara-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tur-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Per-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Th-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ur-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ru-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Pol-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Vie-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Prs-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tgl-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Bur-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Lit-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ukr-kat',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    //alt
    {text:'',type:'E-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'N-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'B-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'T-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Kku-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Kso-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Som-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tir-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ara-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tur-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Per-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Th-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ur-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ru-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Pol-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Vie-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Prs-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tgl-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Bur-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Lit-alt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    //def
    {text:'',type:'E-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'N-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'B-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'T-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kku-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kso-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Som-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tir-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ara-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tur-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Per-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Th-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ur-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ru-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Pol-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Vie-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Prs-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tgl-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Bur-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Lit-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ukr-def',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    //til
    {text:'',type:'E-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'N-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'B-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'T-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kku-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kso-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Som-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tir-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ara-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tur-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Per-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Th-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ur-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ru-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Pol-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Vie-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Prs-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tgl-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Bur-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Lit-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ukr-til',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    //kom
    {text:'',type:'E-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'N-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'B-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'T-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kku-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kso-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Som-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tir-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ara-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tur-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Per-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Th-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ur-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ru-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Pol-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Vie-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Prs-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tgl-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Bur-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Lit-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ukr-kom',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    //mor
    {text:'',type:'E-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'N-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'B-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'T-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Kku-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Kso-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Som-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tir-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ara-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tur-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Per-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Th-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ur-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ru-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Po-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Vie-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Prs-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tgl-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Bur-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Lit-mor',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    //utt
    {text:'',type:'E-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'N-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'B-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'T-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Kku-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Kso-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Som-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tir-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ara-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tur-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Per-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Th-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ur-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Ru-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Pol-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Vie-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Prs-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Tgl-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Bur-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    // {text:'',type:'Lit-utt',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    //grm
    {text:'',type:'E-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'N-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Nny-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'B-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'T-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kku-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Kso-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Som-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tir-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ara-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tur-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Per-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Th-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ur-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ru-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Pol-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Vie-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Prs-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Tgl-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Bur-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Lit-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    {text:'',type:'Ukr-grm',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,fetchedFromDropdown:false},
    //sms
    {text:'',type:'E-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'N-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Nny-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'B-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'T-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Kku-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Kso-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Som-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tir-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ara-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tur-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Per-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Th-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ur-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ru-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Pol-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Vie-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Prs-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tgl-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Bur-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Lit-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ukr-sms',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    //idi
    {text:'',type:'E-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'N-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Nny-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'B-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'T-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Kku-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Kso-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Som-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tir-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ara-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tur-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Per-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Th-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ur-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ru-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Pol-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Vie-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Prs-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tgl-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Bur-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Lit-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ukr-idi',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    //eks
    {text:'',type:'E-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'N-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Nny-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'B-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'T-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Kku-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Kso-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Som-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tir-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ara-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tur-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Per-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Th-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ur-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ru-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Pol-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Vie-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Prs-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Tgl-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Bur-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Lit-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    {text:'',type:'Ukr-eks',id:'',sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:0,fetchedFromDropdown:false},
    //div
    
]
}
export function editLangSet(val,i,id,isNew){
    return [
        {text:'',type:`E-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`N-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Nny-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`B-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`T-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Kku-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Kso-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Som-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Tir-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Ara-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Tur-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Per-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Th-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Ur-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Ru-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Pol-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Vie-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Prs-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Tgl-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Bur-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Lit-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false},
        {text:'',type:`Ukr-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,index:i,isPermitted:false,fetchedFromDropdown:false}
    ];
}
export function editSmsDivSet(val,i,id){
    return [
        {text:'',type:`E-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`N-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Nny-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`B-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`T-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Kku-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Kso-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Som-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Tir-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Ara-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Tur-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Per-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Th-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Ur-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Ru-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Pol-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Vie-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Prs-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Tgl-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Bur-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Lit-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false},
        {text:'',type:`Ukr-${val}`,id:id,sub_id:'',isEditable:'',isNew:false,preview_sub_id:'',isPermitted:false,index:i,fetchedFromDropdown:false}
    ];
}
export function dropdown_editSmsDivSet(val,i,id,sub_id_sequence){
    return [
        {text:'',type:`E-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence,fetchedFromDropdown:true},
        // {text:'',type:`E-div`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,divCount:0,preview_sub_id:sub_id_sequence+1,fetchedFromDropdown:true},
        {text:'',type:`Nny-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+2,fetchedFromDropdown:true},
        {text:'',type:`B-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+3,fetchedFromDropdown:true},
        {text:'',type:`T-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+4,fetchedFromDropdown:true},
        {text:'',type:`Kku-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+5,fetchedFromDropdown:true},
        {text:'',type:`Kso-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+6,fetchedFromDropdown:true},
        {text:'',type:`Som-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+7,fetchedFromDropdown:true},
        {text:'',type:`Tir-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+8,fetchedFromDropdown:true},
        {text:'',type:`Ara-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+9,fetchedFromDropdown:true},
        {text:'',type:`Tur-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+10,fetchedFromDropdown:true},
        {text:'',type:`Per-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+11,fetchedFromDropdown:true},
        {text:'',type:`Th-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+12,fetchedFromDropdown:true},
        {text:'',type:`Ur-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+13,fetchedFromDropdown:true},
        {text:'',type:`Ru-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+14,fetchedFromDropdown:true},
        {text:'',type:`Pol-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+15,fetchedFromDropdown:true},
        {text:'',type:`Vie-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+16,fetchedFromDropdown:true},
        {text:'',type:`Prs-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+17,fetchedFromDropdown:true},
        {text:'',type:`Tgl-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+18,fetchedFromDropdown:true},
        {text:'',type:`Bur-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+19,fetchedFromDropdown:true},
        {text:'',type:`Lit-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+20,fetchedFromDropdown:true},
        {text:'',type:`Ukr-${val}`,id:id,sub_id:'',isEditable:'',index:i,isNew:true,isPermitted:false,preview_sub_id:sub_id_sequence+20,fetchedFromDropdown:true}
    ];
}
export function dropdown_editLangSet(val,i,id,isNew,sub_id_sequence){
    return [
        {text:'',type:`E-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence,fetchedFromDropdown:true},
        {text:'',type:`Nny-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+1,fetchedFromDropdown:true},
        {text:'',type:`B-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+2,fetchedFromDropdown:true},
        {text:'',type:`T-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+3,fetchedFromDropdown:true},
        {text:'',type:`Kku-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+4,fetchedFromDropdown:true},
        {text:'',type:`Kso-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+5,fetchedFromDropdown:true},
        {text:'',type:`Som-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+6,fetchedFromDropdown:true},
        {text:'',type:`Tir-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+7,fetchedFromDropdown:true},
        {text:'',type:`Ara-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+8,fetchedFromDropdown:true},
        {text:'',type:`Tur-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+9,fetchedFromDropdown:true},
        {text:'',type:`Per-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+10,fetchedFromDropdown:true},
        {text:'',type:`Th-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+11,fetchedFromDropdown:true},
        {text:'',type:`Ur-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+12,fetchedFromDropdown:true},
        {text:'',type:`Ru-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+13,fetchedFromDropdown:true},
        {text:'',type:`Pol-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+14,fetchedFromDropdown:true},
        {text:'',type:`Vie-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+15,fetchedFromDropdown:true},
        {text:'',type:`Prs-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+16,fetchedFromDropdown:true},
        {text:'',type:`Tgl-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+17,fetchedFromDropdown:true},
        {text:'',type:`Bur-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+18,fetchedFromDropdown:true},
        {text:'',type:`Lit-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+19,fetchedFromDropdown:true},
        {text:'',type:`Ukr-${val}`,id:id,sub_id:'',isEditable:'',isNew:isNew,isPermitted:false,index:i,preview_sub_id:sub_id_sequence+20,fetchedFromDropdown:true}
    ];
}