// @ts-nocheck
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store/index';
import Acl from './acl'
import App from './views/App';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import VueRouterBackButton from 'vue-router-back-button';
import Toasted from 'vue-toasted';
import VeeValidate from 'vee-validate';
import VueProgressBar from 'vue-progressbar';
import VueI18n from 'vue-i18n';
import eng from './i18n/eng.json';
import base from './base';
import './directive/animate';
import VModal from 'vue-js-modal';
// import JsonExcel from "vue-json-excel";
import VCalendar from 'v-calendar';
import VueExcelXlsx from "vue-excel-xlsx";
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
Vue.use(VueTimepicker);
Vue.use(VueExcelXlsx);
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(VModal)
Vue.use(VueRouterBackButton, { router });
Vue.use(Acl, { router: router, init: 'public', store: store });
// Vue.component("downloadExcel", JsonExcel);
Vue.use(VeeValidate, {
	fieldsBagName: 'vvFields'
});

Vue.use(VCalendar, {
	componentPrefix: 'vc'
});
/**
 * Toaster settings
 */
Vue.use(Toasted, {
	duration: 9000,
	action: {
		class: "toaster_anchor_color",
		text: 'Cancel',
		onClick:
			/**
			 * @param {any} _e
			 * @param {{ goAway: (arg0: number) => void; }} toastObject
			 */
			(_e, toastObject) => {
				toastObject.goAway(0);
			}
	}
})
Vue.prototype.$eventBus = new Vue();


var options = {
	color: '#ffb000',
	failedColor: '#874b4b',
	thickness: '6px',
	transition: {
		speed: '0.5s',
		opacity: '0.6s',
		termination: 50
	},
	autoRevert: true,
	location: 'top',
	inverse: false
}

Vue.use(VueProgressBar, options)

var messages = {
	eng: {
		message: eng
	}
}

var i18n = new VueI18n({
	locale: 'eng',
	messages, // set locale messages
})

/**
 * Global path variable for api call
 */
base.interceptors.request.use(config => {
	if (localStorage.getItem("editorStore.user") !== null) {
		var user = JSON.parse(localStorage.getItem("editorStore.user"));
		config.headers.common['id'] = user.id;
	}
	app.$Progress.parseMeta({
		color: '#ffb000',
		failedColor: '#874b4b',
		thickness: '6px',
		transition: {
			speed: '1s',
			opacity: '0.8s'
		},
		autoRevert: true,
		location: 'top',
		inverse: false
	})
	app.$Progress.start();
	return config;
}, (error) => {
	app.$Progress.fail();
	return Promise.reject(error);
});

base.interceptors.response.use(response => {
	if (response.status == '200') {
		app.$Progress.finish();
	} else {
		app.$Progress.fail();
	}
	return response;
}, (error) => {
	app.$Progress.fail();
	if (error.response.status == 401 &&
		router.currentRoute.fullPath !== '/' &&
		router.currentRoute.fullPath !== '/forget') {
		Vue.prototype.$eventBus.$emit('tokenexpire', true);
	}
	return Promise.reject(error);
});

Vue.prototype.$http = base;

router.beforeEach((to, from, next) => {
	Vue.prototype.$eventBus.$emit('update-loading', true);
	var user = JSON.parse(localStorage.getItem('editorStore.user'));
	if (to.path === '/' || to.path === '/forget') {
		if (localStorage.getItem('editorStore.jwt') !== null) {
			if (user.is_admin) {
				next({
					name: 'dashboard'
				});
			}
			else if (user.is_editor) {
				next({
					name: 'editor-dashboard',
					params:{page:1}
				});
			}
			else {
				next({
					name: 'translator-dashboard'
				});
			}
		}
		next();
	} else {
		if (to.meta.is_admin === true &&
			user !== null &&
			user.is_admin === false && user.is_editor === false &&
			to.meta.requiresAuth === true) {
			next({
				name: 'translator-dashboard'
			});
		} else {
			if (to.meta.requiresAuth === true &&
				user === null) {
				next({
					name: 'login', params: { loggedIn: false }
				});
			} else {
				next();
			}
		}
	}
})

router.afterEach(() => {
	Vue.prototype.$eventBus.$emit('update-loading', false);
})

var app = new Vue({
	el: '#app',
	i18n,
	store,
	components: {
		App
	},
	router
});

export default app;
