<template>
<footer>
    <div class="footer-container">
        <div class="footer-contact">
            <img class="footer-logo" src="../../images/logo-footer.svg" alt="Oslo metropolitan university - storbyuniversitetet logo">
            <div class="footer-info">
                <div>{{ $t('message.postboks-4-St-Olavs-plass') }}</div>
				<div>{{ $t('message.0130-Oslo') }}</div>
				<div>{{ $t('message.tlf-67-23-50-00') }}</div>
			</div>
			<div class="footer-action">
				<a class="a-link black" href="mailto:no-reply@hyper.no">{{ $t('message.kontakt-oss') }}</a>
			</div>
        </div>

		<ul class="footer-links">
			<li class="footer-link">
				<a class="a-link black" href="https://www.oslomet.no/om/arrangement/arrangementoversikt">
					<span>{{ $t('message.arrangementoversikt') }}</span>
				</a>
            </li>

			<li class="footer-link">
				<a class="a-link black" href="https://www.oslomet.no/om/ansatteoversikt">
					<span>{{ $t('message.ansatteoversikt') }}</span>
				</a>
            </li>

			<li class="footer-link">
				<a class="a-link black" href="https://www.oslomet.no/alumni">
					<span>{{ $t('message.alumni') }}</span>
				</a>
            </li>

			<li class="footer-link">
				<a class="a-link black" href="https://www.oslomet.no/om/cookies">
					<span>{{ $t('message.cookies-pa-oslomet-no') }}</span>
				</a>
			</li>

			<li class="footer-link">
				<a class="a-link black" href="https://www.oslomet.no/om/pressekontakt">
					<span>{{ $t('message.pressekontakt') }}</span>
				</a>
			</li>

			<li class="footer-link">
				<a class="a-link black"  href="https://www.oslomet.no/om/tilgjengelighetserklering">
					<span>{{ $t('message.tilgjengelighetserklæring') }}</span>
				</a>
			</li>

			<li class="footer-link">
				<a class="a-link" href="https://www.oslomet.no/en/english">
					<span>{{ $t('message.english-site') }}</span>
				</a>
			</li>
			<li class="footer-link">
				<a class="a-link" href="https://www.oslomet.no/admin/content/location/55">
					<span>{{ $t('message.admin') }}</span>
				</a>
			</li>
		</ul>

		<div class="footer-social">
			<a class="footer-social-link" href="https://www.facebook.com/oslomet/" target="_blank">
				<i class="icon icon-facebook"></i> {{ $t('message.facebook') }}
			</a>

			<a class="footer-social-link" href="https://twitter.com/oslomet" target="_blank">
				<i class="icon icon-twitter"></i> {{ $t('message.twitter') }}
			</a>

			<a class="footer-social-link" href="https://no.linkedin.com/school/oslomet/" target="_blank">
				<i class="icon icon-linkedin"></i> {{ $t('message.linkedIn') }}
			</a>

			<a class="footer-social-link"  href="https://www.flickr.com/photos/hioa/" target="_blank">
				<i class="icon icon-flickr"></i> {{ $t('message.flickr') }}
			</a>

			<a class="footer-social-link" href="https://www.instagram.com/oslomet/?hl=nb" target="_blank">
				<i class="icon icon-instagram"></i> {{ $t('message.instagram') }}
			</a>
        </div>
    </div>
</footer>
</template>

<script>
export default {
	name: 'Footer',
	mounted(){
		this.footerAlign();

		$( window ).resize(()=>{
			this.footerAlign();
		});
		window.addEventListener('scroll', this.footerAlign());
	},
	methods:{
		footerAlign(){
			$('footer').css('display', 'block');
			$('footer').css('height', 'auto');
			var footerHeight = $('footer').innerHeight();
			//$('body').css('padding-bottom', footerHeight);
			$('footer').css('height', footerHeight);
		}
	}
};
</script>
