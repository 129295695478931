<template>
	<main>
		<div class="loader" v-if="routerLoading">
			<img src="/images/oslo-spinner-new.gif">
		</div>
		<template v-else>
			<Header
				:isLoggedIn="isLoggedIn"
				v-if="isLoggedIn"
				@updateisLoggedIn="changeisLoggedIn">
			</Header>
			<router-view :key="$route.fullPath" @loggedIn="change"></router-view>
			<vue-progress-bar></vue-progress-bar>
			<Footer
				v-if="isLoggedIn && $route.name !=='userboard'">
			</Footer>
		</template>
		<modal name="tokenexpire" @opened="removeStyle">
			<div class="container">
				<div class="" id="myModal" role="dialog">
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="warning-modal">
								<span class="a-important-icon">!</span>
								<div class="warning-content">
									<p class="a-paragraph">
										{{ $t('message.token_expire_msg') }}
									</p>
									<div class="buttons">
										<button class="btn login-btn ml-2" @click="close()">{{ $t('message.ok') }}</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</modal>
	</main>
</template>
<script>
import Header from '../components/Header';
import Footer from '../components/Footer';
import store from '../store/index';

export default {
	components: {
		Header,
		Footer
	},
	data() {
		return {
			name: null,
			user_type: 0,
			isLoggedIn: localStorage.getItem('editorStore.jwt') != null,
			routerLoading:false
		};
	},
	methods: {
		removeStyle(){
	  		$('.v--modal-box.v--modal').removeAttr("style");
		},
		close(){
			this.$modal.hide('tokenexpire');
			localStorage.removeItem('editorStore.jwt');
			localStorage.removeItem('editorStore.user');
			this.$router.push({ name: 'login', params: {loggedIn: false}});
		},
		changeisLoggedIn() {
			this.isLoggedIn = false;
		},
		setDefaults() {
			if (this.isLoggedIn) {
				let user = JSON.parse(localStorage.getItem('editorStore.user'));
				this.name = user.name;
				this.user_type = user.is_admin;
			}
		},
		canceledRefresToken(){
			this.$modal.hide('refresh_token');
			this.refreshToken_canceled=true;
		},
		change() {
			this.isLoggedIn = localStorage.getItem('editorStore.jwt') != null;
			this.setDefaults();
		},
		clear(){
			localStorage.clear();
			store.commit('resetState');
			this.changeisLoggedIn();
			this.$toasted.success('You have successfully logged out !');
			this.$router.push('/');
		}
	},
	mounted () {
		if(localStorage.getItem('userRole') != null){
			var role=localStorage.getItem('userRole');
			store.commit('userRole',role);
		}
    	//  [App.vue specific] When App.vue is finish loading finish the progress bar
		this.$Progress.finish();

		this.$eventBus.$on('tokenexpire',data => {
			this.$modal.show('tokenexpire');
		});
		this.$eventBus.$on('update-loading',data => {
			this.routerLoading = data;
		});
  	},
	created () {
		//  [App.vue specific] When App.vue is first loaded start the progress bar
		this.$Progress.start()
		//  hook the progress bar to start before we move router-view
		this.$router.beforeEach((to, from, next) => {
		//  does the page we want to go to have a meta.progress object
		if (to.meta.progress !== undefined) {
			let meta = to.meta.progress
			// parse meta tags
			this.$Progress.parseMeta(meta)
		}
		//  start the progress bar
		this.$Progress.start()
		//  continue to next page
		next()
		})
		//  hook the progress bar to finish after we've finished moving router-view
		this.$router.afterEach((to, from) => {
		//  finish the progress bar
		this.$Progress.finish()
		})
	},
	watch:{
            $route:function(newval,oldval){
                if(newval.params.loggedIn ==false){
					this.isLoggedIn = false;
				}
				if(newval.path !==oldval.path){
					if((newval.path === '/' || newval.path === '/forget' ) && this.isLoggedIn == true ){
						let user = JSON.parse(localStorage.getItem('editorStore.user'));
						if (user.is_admin) {
							this.$router.push({ name: 'dashboard' });
						}
						else if(user.is_editor){
							this.$router.push({ path: 'listchangerequests',	query:{page:1} });
						}
						else {
							this.$router.push({ name: 'translator-dashboard' });
						}
					}
					$('.modal-backdrop.fade.show').remove();
					$("body").removeClass("modal-open");
					$("body").css({ 'padding-right' : '' });
				}
            }
	}
};
</script>
<style lang="scss">
	@import url("../../icons/css/all.css");
</style>
