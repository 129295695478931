<template>
  <div class="o-header">
    <div>
      <div class="header-wrapper">
        <div class="header-top">
          <a
		  	class="logo"
		  	@click="navigateto()">
            <svg
              width="60px"
              height="36px"
              viewBox="0 0 60 36"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="logo-color" transform="translate(-23.000000, -22.000000)" fill="#222222">
                  <g transform="translate(23.000000, 22.000000)">
                    <polygon
                      id="Fill-11"
                      points="57.8624118 30.8473235 56.0465294 29.0314412 49.5541765 35.5237941 47.703 33.6717353 54.1953529 27.1802647 52.3785882 25.3635 54.0894706 23.6526176 59.5732941 29.1364412"
                    ></polygon>
                    <polygon
                      id="Fill-13"
                      points="46.4544706 29.0481176 44.7665294 30.7360588 39.9938824 25.9634118 48.1971176 17.7601765 52.9653529 22.5284118 51.2782941 24.2154706 48.3144706 21.2525294 46.7553529 22.8107647 49.443 25.4992941 47.8494706 27.0919412 45.1618235 24.4042941 43.4862353 26.0807647"
                    ></polygon>
                    <polygon
                      id="Fill-15"
                      points="38.4021176 24.3710294 36.6091765 22.5789706 41.2027059 17.9589706 35.073 21.0375 33.4097647 19.3901471 36.4874118 13.1933824 31.8665294 17.8363235 30.0735882 16.0433824 38.2768235 7.84014706 40.6671176 10.2304412 37.0044706 17.4710294 44.2027059 13.7660294 46.6053529 16.1686765"
                    ></polygon>
                    <path
                      d="M30.6798529,3.76491176 C29.2857353,2.36991176 27.2810294,2.31255882 26.0157353,3.57697059 C24.7036765,4.88902941 24.7857353,6.87079412 26.1798529,8.26491176 C27.5748529,9.65991176 29.5548529,9.74108824 30.8677941,8.42902941 C32.1445588,7.15226471 32.0748529,5.15902941 30.6798529,3.76491176 M24.2351471,10.2096176 C21.8325,7.80697059 21.7857353,4.22197059 24.2227941,1.78402941 C26.6254412,-0.618617647 30.2227941,-0.583323529 32.6254412,1.81932353 C35.0272059,4.22197059 35.0633824,7.81932353 32.6607353,10.2210882 C30.2113235,12.6713824 26.6369118,12.6113824 24.2351471,10.2096176"
                      id="Fill-6"
                    ></path>
                    <polygon
                      id="Fill-9"
                      points="25.8596471 13.4018824 27.5528824 15.0951176 22.782 19.8651176 14.5796471 11.6627647 16.3955294 9.84688235 22.9055294 16.3568824"
                    ></polygon>
                    <path
                      d="M18.4322647,24.4502647 C16.4866765,26.3949706 14.1546176,26.3129118 12.4790294,24.6373235 L14.2957941,22.8205588 C15.0810882,23.6067353 16.0296176,23.6887941 16.9084412,22.8099706 C17.4360882,22.2823235 17.6346176,21.5667353 17.1078529,21.0399706 C16.5449118,20.4779118 15.6546176,20.7355588 14.3893235,21.2985 C12.8425588,21.9311471 10.9437353,22.6335 9.58402941,21.2737941 C8.02579412,19.7164412 8.3655,17.6411471 9.88932353,16.1190882 C11.6822647,14.3243824 13.8731471,14.4540882 15.2566765,15.8367353 L13.5572647,17.5361471 C12.9590294,17.0332059 12.1746176,16.9732059 11.4713824,17.6764412 C10.9552059,18.1917353 10.8616765,18.8252647 11.3072647,19.2708529 C11.7405,19.7040882 12.3378529,19.5285 13.5810882,19.0132059 C15.7137353,18.1687941 17.5410882,17.6764412 19.0296176,19.1649706 C20.5887353,20.7232059 20.1316765,22.7508529 18.4322647,24.4502647"
                      id="Fill-4"
                    ></path>
                    <path
                      d="M1.80917647,24.1979118 C4.21182353,21.7952647 7.80917647,21.8305588 10.2118235,24.2332059 C12.6135882,26.6358529 12.6488824,30.2332059 10.2471176,32.6349706 C7.79770588,35.0852647 4.22329412,35.0261471 1.82064706,32.6243824 C-0.582,30.2217353 -0.627882353,26.6358529 1.80917647,24.1979118 Z M3.60211765,25.9908529 C2.29005882,27.3029118 2.37211765,29.2837941 3.76623529,30.6787941 C5.16123529,32.0737941 7.14123529,32.1549706 8.45417647,30.8420294 C9.73094118,29.5652647 9.66123529,27.5729118 8.26623529,26.1779118 C6.87211765,24.7837941 4.86741176,24.7255588 3.60211765,25.9908529 Z"
                      id="Fill-1"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <div class="icon-box">
            <div
              class="a-header-item false"
              v-if="user !== null && user.is_admin"
              @click="routeTo('dashboard')"
            >{{$t('message.dashboard')}}</div>
			<div
              class="a-header-item false"
              v-if="user !== null"
              @click="routeTo('help')"
            >{{$t('message.help')}}<i class="fas fa-question-circle ml-1"></i></div>
            <div class="a-header-item false" id="menu" @click="menuClick">
              {{$t('message.editor_portal')}}
              <div class="menu-icon">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu">
        <nav class="menu-inner">
          <ul>
            <li>
              <a @click="userProfile">{{$t('message.my_profile')}}</a>
            </li>
            <li>
              <a @click="google2FA">{{$t('message.google_2fa_settings')}}</a>
            </li>
			<li  v-if="user.is_admin">
              <a @click="trafficToolConfig">{{$t('message.traffic_tool_configuration')}}</a>
            </li>
			<li v-if="user.is_admin">
              <a @click="rollBackNavigate">{{$t('message.recycle_bin')}}</a>
            </li>
			<li v-if="(user.is_admin || (!user.is_admin && !user.is_editor && create_permission.includes('E')))" >
				<a @click="addWord">{{$t('message.add_word')}}</a>
			</li>
			<li v-if="user.is_admin">
              <a @click="logViewer">{{ $t('message.logs') }}</a>
            </li>
            <li>
              <a @click="logout">{{$t('message.logout')}}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import store from '../store/index';
import {addWord_types} from '../store/types';
import { mapFields } from 'vuex-map-fields';
export default {
	name: 'Header',
	props: ['isLoggedIn'],
	data() {
		return {
			name: null,
			user_type: 0,
			user: {
				is_admin: false
			},
			scrollPosition: 0,
			showHeader:false,
			scrollClass:false,
			IsChange:false
		};
	},
	mounted() {
		this.$store.dispatch('getCreatePermission');
		window.addEventListener('scroll', this.updateScroll);
		this.user = JSON.parse(localStorage.getItem('editorStore.user'))||{is_admin: false};
		this.$eventBus.$on('IsChanged',data =>{
			if(data.changedLength){
				this.IsChange = true;
			}
			else{
				this.IsChange=false;
			}
			});
		
	},
	computed: {
   		...mapFields([
      		'create_permission'
    	])
  	},
	methods: {
		menuClick(){
			$('body').toggleClass('overflow-hidden');
			$('.menu').toggleClass('open');
			$('.sidebar-wrapper').toggleClass('z-index');
			$(document).find('.menu-icon').toggleClass('open');
		},
		updateScroll() {
			this.scrollClass = true;
			this.showHeader = window.scrollY == 0 ? false : (this.scrollPosition > window.scrollY);
			this.scrollPosition = window.scrollY;
		},
		routeTo(val) {
			if(this.$route.name==val){
				$("body,html").animate(
						{
						scrollTop: 0
						},
						60
					);
			}
			$('.menu').removeClass('open');
     		$('.menu-icon').removeClass('open');
			$('body').removeClass('overflow-hidden');
			this.$router.push({ name: val });
		},
		//logs the user out of the application and routes the user to the homepage.
		logout() {
			$('#menu').click();
			if(this.IsChange){
				this.$modal.show('discardchanges', { method: this.clearUser });
			}else{
				this.clearUser();
			}
		},
		clearUser(){
			this.$eventBus.$emit('IsChanged',
				{
					changedLength:0
				}
			);
			localStorage.clear();
			store.commit('resetState');
			this.$emit('updateisLoggedIn', this.isLoggedIn);
			this.$toasted.success('You have successfully logged out !');
			this.$router.push('/');
		},
		listusers() {
			this.$router.push({ name: 'listusers' });
		},
		listroles() {
			this.$router.push({ name: 'listroles' });
		},
		listpermissions() {
			this.$router.push({ name: 'listpermissions' });
		},
		userProfile() {
			$('#menu').click();
			this.$router.push({ name: 'profile' });
		},
		google2FA() {
			$('#menu').click();
			this.$router.push({ name: '2fa' });
		},
		addWord(){
			$('#menu').click();
			this.$store.commit('restore_state',addWord_types());
			this.$router.push({
					name: 'addword',
					params: {dictionary:'message.bokmal-monolingual'}
				});
		},
		navigateto(){
			$('.menu').removeClass('open');
			$('.menu-icon').removeClass('open');
			$('body').removeClass('overflow-hidden');
			if (this.user!=null && this.user.is_admin) {
				this.$router.push({ name: 'dashboard' });
			}
			else if(this.user!=null && this.user.is_editor){
				this.$router.push({ name: 'editor-dashboard' });
			}
			else {
				this.$router.push({ name: 'translator-dashboard' });
			}
		},
		rollBackNavigate(){
			$('#menu').click();
			this.$router.push({ name: 'listchangerequestsdeleted' , params:{page:1}});
		},
		logViewer(){
			$('#menu').click();
			this.$router.push({ name: 'logViewer' });
		},
		trafficToolConfig () {
			$('#menu').click();
			this.$router.push({ name: 'trafficConfig' });
		}
	}
};
</script>
